// Here you can add other styles
.sidebar {
  position: fixed;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
#sidebar-wrapper{
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins');

body {
  font-family: 'Poppins', serif;
}

.player {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  background-color: #212527;
  width: 70%;

  .song {
    user-select: none;
    margin: 0 20px;
    .song__title {
      text-align: left;
      margin: 0;
      color: white;
      font-family: "Permanent Marker", cursive;
      font-weight: normal;
      font-size: 3.5em;

      &:hover {
        color: greenyellow;
      }
    }

    .song__artist {
      margin: 0;
      color: white;
      font-family: "Rock Salt", cursive;
      font-weight: normal;
      font-size: 1em;

      &:hover {
        color: greenyellow;
        cursor: pointer;
      }
    }
  }

  .controls {
    flex-grow: 1;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .player__button {
    width: fit-content;
    margin-bottom: 15px;
    background-color: transparent;
    border: none;

    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
      svg {
        color: greenyellow;
      }
    }

    svg {
      font-size: 4em;
      color: white;
    }
  }

  .bar {
    user-select: none;
    width: 100%;
    display: flex;
    align-items: center;

    .bar__time {
      color: white;
      font-size: 16px;
    }

    .bar__progress {
      flex: 1;
      border-radius: 5px;
      margin: 0 20px;
      height: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .bar__progress__knob {
        position: relative;
        height: 16px;
        width: 16px;
        border: 1.5px solid white;
        border-radius: 50%;
        background-color: orange;
      }
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;

    .controls {
      width: 100%;
      margin-top: 20px;
    }

    .bar {
      width: 90%;
    }
  }

  @media screen and (max-width: 500px) {
    .song {
      .song__title {
        font-size: 2.5em;
      }

      .song__artist {
        font-size: 0.8em;
      }
    }
  }
}

